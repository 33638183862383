import React from "react";
import { Card } from "@wellth/web-ui";
import CARD_STYLES from "../styles";
import styles from "./styles";

interface Props {
  total: number;
  last30: number;
  last7: number;
}

interface AdherenceCardProps extends Props {
  header?: string;
}

const prettyDisplayPercent = (number: number): string =>
  `${Number.isNaN(number) ? "--" : Math.round(number)}%`;

const Adherence: React.FC<AdherenceCardProps> = ({
  header,
  last7,
  last30,
  total,
}) => (
  <>
    <div style={styles.allTasksHeader}>{header}</div>
    <div style={styles.container}>
      <div>
        <p style={styles.title}>Last 7</p>
        <p style={styles.content}>{prettyDisplayPercent(last7)}</p>
      </div>
      <div>
        <p style={styles.title}>Last 30</p>
        <p style={styles.content}>{prettyDisplayPercent(last30)}</p>
      </div>
      <div>
        <p style={styles.title}>Total</p>
        <p style={styles.content}>{prettyDisplayPercent(total)}</p>
      </div>
    </div>
  </>
);

const ManualCredits: React.FC<{
  totalCount: number;
  currentRewardPeriodCount: number;
  adherenceWithout: number;
}> = ({ totalCount, currentRewardPeriodCount, adherenceWithout }) => (
  <>
    <div style={{ ...styles.allTasksHeader, paddingTop: "1rem" }}>
      Manual Credits
    </div>
    <div style={styles.container}>
      <div>
        <p style={styles.title}>Year to Date</p>
        <p style={styles.content}>{totalCount}</p>
      </div>
      <div>
        <p style={styles.title}>This Reward Period</p>
        <p style={styles.content}>{currentRewardPeriodCount}</p>
      </div>
      <div>
        <p style={styles.title}>All Adherence w/o Credits</p>
        <p style={styles.content}>{prettyDisplayPercent(adherenceWithout)}</p>
      </div>
    </div>
  </>
);

interface ManualCreditDetails {
  totalCount: number;
  currentRewardPeriodCount: number;
  adherenceWithout: number;
}

interface AdherenceProps {
  allTasksRemindersAdherence: Props;
  manualCreditDetails: ManualCreditDetails;
}

export const AdherenceCard: React.FC<AdherenceProps> = ({
  allTasksRemindersAdherence,
  manualCreditDetails: {
    totalCount,
    currentRewardPeriodCount,
    adherenceWithout,
  },
}) => (
  <Card
    headStyle={CARD_STYLES.head}
    bodyStyle={CARD_STYLES.body}
    style={CARD_STYLES.card}
    title="Adherence"
  >
    <Adherence
      last30={allTasksRemindersAdherence.last30}
      last7={allTasksRemindersAdherence.last7}
      total={allTasksRemindersAdherence.total}
      header="All Tasks"
    />
    <ManualCredits
      totalCount={totalCount}
      currentRewardPeriodCount={currentRewardPeriodCount}
      adherenceWithout={adherenceWithout}
    />
  </Card>
);

export default AdherenceCard;
